import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { DataminrTeamsConnectorConfig } from "./App";

ReactDOM.render(
  <React.StrictMode>
    <DataminrTeamsConnectorConfig />
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);
