import * as React from "react";
import {
  Provider,
  Flex,
  Text,
  Button,
  Grid,
  Header,
} from "@fluentui/react-northstar";
import { useState, useEffect } from "react";
import { useTeams } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";
import {
  WatchListSelection,
  IWatchListSelectionProps,
} from "./components/watch-list-selection";
import "./App.css";
import { getDMConfig } from "./config/config";
import { Context } from "@microsoft/teams-js";

/**
 * Implementation of the dataminrTeamsConnector Connector connect page
 */

interface DmToken {
  dmaToken: string;
  refreshToken: string;
  expire: number;
}

interface Watchlist {
  id: string;
  brands: Array<string>;
}

interface IntegrationObject {
  vertical: string;
  channel_url: string;
  watchlists: Array<Watchlist>;
}

class Cache {
  static data: Array<IWatchListSelectionProps>;
  static savedData: Array<IWatchListSelectionProps>;
  static uniqueKey: string;
  static deliverySettingId: string;

  static token: DmToken;
  static companyInfo: any;
}

export const DataminrTeamsConnectorConfig = () => {
  const [{ theme, context }] = useTeams();
  const [dmWatchlist, setDmWatchlist] = useState<
    Array<IWatchListSelectionProps>
  >([]);
  const [dmToken, setDmToken] = useState<DmToken>();
  const [dmCompanyInfo, setDmCompanyInfo] = useState<any>();
  const [dmNoMSTeamsAccess, setDmNoMSTeamsAccess] = useState<boolean>(false);
  const error_code = "HTTP::ERR::401";
  const config = getDMConfig();

  // callback func passed as parameter to watch-list-selection component
  // updates: array of selected watchlists/priority
  const callback = (updates: IWatchListSelectionProps[]) => {
    cacheWatchlist(updates, true);

    const someSelected = updates.some((wl: IWatchListSelectionProps) => {
      return Object.values(wl.priority).some(Boolean);
    });
    microsoftTeams.settings.setValidityState(someSelected);
  };

  const fetchByMethod = async (
    apiUrl: string,
    token: string,
    method: string
  ) => {
    let response = await fetch(apiUrl, {
      mode: "cors",
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: `DmAuth ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const fetchData = async (apiUrl: string, token: string, method: string, payload: any) => {
    let response = await fetch(apiUrl, {
      mode: "cors",
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `DmAuth ${token}`,
      },
      body: payload ? JSON.stringify(payload) : undefined,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };


  // subscribe to save and remove handler provided by teams-js sdk.
  useEffect(() => {
    if (context) {
      // handler called when user click on Remove button on Configuration page. Call delete api to remove
      // configuration data from server.
      microsoftTeams.settings.registerOnRemoveHandler(
        (delEvent: microsoftTeams.settings.RemoveEvent) => {
            const key = `${Cache.deliverySettingId}`;
            const removeConfigApiUrl = `${config.save_config_api
              }/${window.encodeURIComponent(key)}`;
            (async () => {
              try {
                let response = await fetchData(
                  removeConfigApiUrl,
                  Cache.token.dmaToken,
                  "DELETE",null
                );
                if(response.error)
                {
                  console.log("DM::registerOnRemoveHandler failed", response.error)
                  delEvent.notifyFailure("Unable to remove configuration data");
                }
                else{
                  delEvent.notifySuccess();
                }
              } catch (error) {
                console.error("DM::registerOnRemoveHandler failed", error);
                delEvent.notifyFailure("Unable to remove configuration data");
              }
            })();
        }
      );

      // handler called when user click on Save button on Configuration page. Saves watchlists on Teams
      // as well as calls api to save watchlists + webhook url on the server
      microsoftTeams.settings.registerOnSaveHandler(
        (saveEvent: microsoftTeams.settings.SaveEvent) => {
          // returns selected watchlists (if any one priority is selected)
          const selected = Cache.data.filter((wl: IWatchListSelectionProps) => {
            return Object.values(wl.priority).some(Boolean);
          });

          // selectedSettings & selectedString used to store configuration on Teams
          const selectedSettings = selected.map(
            (s: IWatchListSelectionProps): any => {
              return {
                id: s.id,
                priority: { ...s.priority },
              };
            }
          );
          const selectedString = selected
            .map((s: IWatchListSelectionProps): string => {
              return s.name;
            })
            .join(", ");

          let settingObject: any = {
            watchlists: selectedSettings,
            uniqueId: "",
            deliverySettingId: "",
          };

          const settings: any = {
            entityId: JSON.stringify(settingObject),
            contentUrl: `${window.location.origin}/?name={loginHint}&tenant={tid}&group={groupId}&theme={theme}`,
            removeUrl: `${window.location.origin}/?name={loginHint}&tenant={tid}&group={groupId}&theme={theme}`,
            configName: `Watchlists: ${selectedString}`,
          };

          // save configuration data on Teams
          microsoftTeams.settings.setSettings(settings);

          // prepare selected watchlist to be stored on DM server
          const selectedWatchlists = selected.map(
            (s: IWatchListSelectionProps): Watchlist => {
              return {
                id: s.id.toString(),
                brands: Object.keys(s.priority)
                  .filter((key) => {
                    return s.priority[key] === true;
                  })
                  .map((key) => {
                    return key.toUpperCase();
                  }),
              };
            }
          );

          microsoftTeams.settings.getSettings((setting: any) => {

            // payload: IntegrationObject - formats the data as per API requested format
            let payload: IntegrationObject = {
              vertical: Cache.companyInfo ? Cache.companyInfo.vertical : "",
              channel_url: setting.webhookUrl,
              watchlists: selectedWatchlists,
            };
            if (Cache.deliverySettingId) {
              (async () => {
                try {
                  const put_config_api = `${config.save_config_api
                    }/${window.encodeURIComponent(Cache.deliverySettingId)}`;
                  let response = await fetchData(put_config_api, Cache.token.dmaToken,"PUT", payload);
                  if (response.status_code === 200) {
                    settingObject = {
                      ...settingObject,
                      deliverySettingId: response.deliverySettingId,
                    };
                    settings.entityId = JSON.stringify(settingObject);
                    microsoftTeams.settings.setSettings(settings);
                    saveEvent.notifySuccess();
                  }
                  else {
                    console.error("DM::registerOnSaveHandler failed", response.error);
                    saveEvent.notifyFailure("Unable to save configuration data");
                  }
                } catch (error) {
                  console.error("DM::registerOnSaveHandler failed", error);
                  saveEvent.notifyFailure("Unable to save configuration data");
                }
              })();
            }
            else {
              (async () => {
                try {
                  let response = await fetchData(config.save_config_api, Cache.token.dmaToken,"POST", payload);
                  if (response.status_code === 200) {
                    settingObject = {
                      ...settingObject,
                      deliverySettingId: response.deliverySettingId,
                    };
                    settings.entityId = JSON.stringify(settingObject);
                    microsoftTeams.settings.setSettings(settings);
                    saveEvent.notifySuccess();
                  }
                  else {
                    console.error("DM::registerOnSaveHandler failed", response.error);
                    saveEvent.notifyFailure("Unable to save configuration data");
                  }
                } catch (error) {
                  console.error("DM::registerOnSaveHandler failed", error);
                  saveEvent.notifyFailure("Unable to save configuration data");
                }
              })();
            }
          });
        }
      );

      // returns previously stored watchlists from Teams. This is used to pre-select watchlist when
      // user returns to modify existing configuration
      microsoftTeams.settings.getSettings((settings: any) => {
        cacheSettings(settings);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  // one time onload, calls api to fetch Company Info and Watchlsits and caches for the session
  useEffect(() => {
    if (!dmToken || !dmToken.dmaToken) {
      return;
    }

    const selfApiUrl = config.get_self_api;
    (async () => {
      const companyInfo = await fetchByMethod(
        selfApiUrl,
        dmToken.dmaToken,
        "GET"
      );
      cacheCompanyInfo(companyInfo);
    })();

    const watchlistApiUrl = config.get_lists_api;
    (async () => {
      const watchlists = await fetchByMethod(
        watchlistApiUrl,
        dmToken.dmaToken,
        "GET"
      );
      cacheWatchlist(watchlists, false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dmToken]);

  // Info on all cache.... functions. Because of issue accessing state created using useState inside
  // Teams callback functions, I am Cache object to temporarily store data

  const fetchDeliverySettingId = () => {
    microsoftTeams.getContext((context: Context) => {

      microsoftTeams.settings.getSettings((settings: any) => {
        if (settings.entityId && !JSON.parse(settings.entityId).deliverySettingId && JSON.parse(settings.entityId).uniqueId) {
          const deliveryInfoApi = `${config.get_delivery_info_api}/${window.encodeURIComponent(`${context.channelId}@${JSON.parse(settings.entityId).uniqueId}`)}`;
          (async () => {
            let response = await fetch(deliveryInfoApi, {
              mode: "cors",
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `DmAuth ${Cache.token.dmaToken}`,
              },
            });
            // Parse the JSON content of the response
            const responseData = await response.json();
            if (responseData.status_code === 200) {
              Cache.deliverySettingId = responseData.deliverySettingId;
              console.log("Successfully retrieved DeliverySettingId ");
            }
            else
            {
              console.log("DeliverySettingId not found, Please contact the support team.");
            }
          })();
        }
      });
    });
  }
  // formats previously selected watchlists and set
  const cacheWatchlist = (
    response: Array<IWatchListSelectionProps> | any,
    forUpdate: boolean
  ) => {
    if (forUpdate) {
      setDmWatchlist([...response]);
      Cache.data = [...response];
    } else if (response && response.watchlists) {
      let allWatchLists: any = [];
      Object.keys(response.watchlists).forEach((key) => {
        if (response.watchlists[key]) {
          allWatchLists = allWatchLists.concat(response.watchlists[key]);
        }
      });

      let watchLists: Array<IWatchListSelectionProps> = allWatchLists.map(
        (w: any) => {
          const isNews =
            getCompanyInfo().vertical === config.news_vertical ? true : false;
          return {
            id: w.id,
            name: w.name,
            isNews,
            priority: isNews
              ? {
                flash: false,
                urgent: false,
                alert: false,
                momentum: false,
                urgentUpdate: false,
              }
              : { flash: false, urgent: false, alert: false },
            callback,
            data: [],
          };
        }
      );

      if (Cache.savedData && Cache.savedData.length > 0) {
        Cache.savedData.forEach((e: IWatchListSelectionProps) => {
          const index = watchLists.findIndex((w: IWatchListSelectionProps) => {
            return e.id === w.id;
          });

          if (index > -1) {
            watchLists[index] = { ...watchLists[index], ...e };
          }
        });
      }

      setDmWatchlist([...watchLists]);
      Cache.data = [...watchLists];
    }
  };

  const cacheToken = (token: DmToken) => {
    setDmToken(token);
    Cache.token = token;
  };

  const cacheCompanyInfo = (companyInfo: any) => {
    setDmCompanyInfo(companyInfo);
    Cache.companyInfo = companyInfo;
  };

  const getCompanyInfo = () => {
    return Cache.companyInfo || dmCompanyInfo || {};
  };

  const cacheSettings = (settings: any) => {
    if (settings && settings.entityId) {
      const settingObject = JSON.parse(settings.entityId);
      Cache.savedData = settingObject.watchlists;
      Cache.uniqueKey = settingObject.uniqueId;
      Cache.deliverySettingId = settingObject.deliverySettingId;
    }
  };

  // called when user clicks on Singin button. Initiate Oauth login workflow
  const initiateLogin = () => {
    login(window.location.origin + config.auth_url);
  };

  // open oauth dialog
  const login = (url: string) => {
    microsoftTeams.authentication.authenticate({
      url: url,
      width: 600,
      height: 600,
      successCallback: (data?: string) => {
        setDmNoMSTeamsAccess(false);
        cacheToken(data != null ? JSON.parse(data) : null);
        fetchDeliverySettingId();
      },
      failureCallback: (reason: any) => {
        if (reason === error_code) {
          console.error("DM::login-failureCallback", reason);
          setDmNoMSTeamsAccess(true);
        }
      },
    });
  };

  const cls =
    context && context.theme === "dark"
      ? { backgroundColor: "#292929", background: "#292929" }
      : {};

  return (
    <Provider theme={theme} style={cls}>
      {!dmToken && (
        <Flex
          fill={true}
          hAlign="center"
          vAlign="center"
          column
          gap="gap.smaller"
          className="signin"
        >
          <Button content="Sign In" onClick={initiateLogin} primary />

          {dmNoMSTeamsAccess && (
            <>
              <br />
              <br />
              <Text
                color="red"
                content="It appears you do not have access to the Dataminr MS Teams connector."
                weight="semilight"
              />
              <Text
                color="red"
                content="Please contact your Dataminr representative for support or email support@dataminr.com."
                weight="semilight"
              />
            </>
          )}
        </Flex>
      )}

      {dmToken && (
        <Flex fill={true} column hAlign="center" vAlign="center">
          {dmWatchlist && dmWatchlist.length > 0 && (
            <Header
              as="h4"
              content="Please choose which watchlists you'd like to push to this channel"
            />
          )}
          {dmWatchlist && dmWatchlist.length === 0 && (
            <Header
              as="h4"
              content="In order to continue with this connector, please go to your Dataminr account and create lists."
            />
          )}
          <Grid columns="4">
            {dmWatchlist &&
              dmWatchlist.length > 0 &&
              dmWatchlist.map((wl: IWatchListSelectionProps) => {
                return (
                  <WatchListSelection key={wl.id} {...wl} data={dmWatchlist} />
                );
              })}
          </Grid>
        </Flex>
      )}
    </Provider>
  );
};
