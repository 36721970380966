import { Checkbox } from "@fluentui/react-northstar";
import React, { useEffect } from "react";

export interface IWatchListPriority {
  flash: boolean;
  urgent: boolean;
  alert: boolean;
  momentum: boolean;
  urgentUpdate: boolean;
  [state: string]: boolean;
}

export interface IWatchListSelectionProps {
  id: number;
  name: string;
  isNews: boolean;
  priority: IWatchListPriority;
  callback: (updates: IWatchListSelectionProps[]) => void;
  data: Array<IWatchListSelectionProps>;
}

export const WatchListSelection = (props: IWatchListSelectionProps) => {
  const priority = props.priority; //? newsPriority : privatePriority;
  const [priorityState, dispatch] = React.useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      ...priority,
    }
  );

  const { id, callback, data } = props;
  useEffect(() => {
    const newItems =
      data &&
      data.map((item: IWatchListSelectionProps) => {
        if (id === item.id) {
          return { ...item, priority: priorityState };
        }
        return item;
      });
    callback(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityState]);

  const someSelected = Object.values(priorityState).some(Boolean)
    ? true
    : false;

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Checkbox
          checked={someSelected}
          aria-controls="flash urgent alert momentum urgentUpdate"
          onChange={(e, data) => {
            props.isNews
              ? dispatch({
                  flash: data?.checked,
                  urgent: data?.checked,
                  alert: data?.checked,
                  momentum: data?.checked,
                  urgentUpdate: data?.checked,
                })
              : dispatch({
                  flash: data?.checked,
                  urgent: data?.checked,
                  alert: data?.checked,
                });
          }}
          label={props.name}
          id="all"
        />
        {someSelected && (
          <div style={{ paddingLeft: 20, display: "block" }}>
            <div style={{ display: "block" }}>
              <Checkbox
                checked={priorityState.flash}
                onChange={(e, data) => {
                  dispatch({
                    flash: data?.checked,
                  });
                }}
                id="flash"
                label="Flash"
              />
            </div>
            <div style={{ display: "block" }}>
              <Checkbox
                checked={priorityState.urgent}
                onChange={(e, data) => {
                  dispatch({
                    urgent: data?.checked,
                  });
                }}
                id="urgent"
                label="Urgent"
              />
            </div>
            <div style={{ display: "block" }}>
              <Checkbox
                checked={priorityState.alert}
                onChange={(e, data) => {
                  dispatch({
                    alert: data?.checked,
                  });
                }}
                id="alert"
                label="Alert"
              />
            </div>

            {props.isNews && (
              <div style={{ display: "block" }}>
                <Checkbox
                  checked={priorityState.momentum}
                  onChange={(e, data) => {
                    dispatch({
                      momentum: data?.checked,
                    });
                  }}
                  id="momentum"
                  label="Momentum"
                />

                <Checkbox
                  checked={priorityState.urgentUpdate}
                  onChange={(e, data) => {
                    dispatch({
                      urgentUpdate: data?.checked,
                    });
                  }}
                  id="urgentUpdate"
                  label="Urgent Update"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
