export const getDMConfig = () => {
  //DEV & TEST
  if (
    window.location.host ===
      "inp-alerts-msteams-connector-build-t-dev.ds.dataminr.com" ||
    window.location.host === "alerts-msteams-connector-dev.dataminr.com"
  ) {
    return {
      get_self_api:
        "https://gateway-stage.dataminr.com/userMgmt/3/users/get_self",
      get_lists_api: "https://gateway-stage.dataminr.com/account/2/get_lists",
      save_config_api:
        "https://gateway-stage.dataminr.com/integrations/1/msteams/config",
      get_delivery_info_api: "https://gateway-stage.dataminr.com/integrations/1/msteams/fetch",
      auth_url: "/auth/authenticate",
      news_vertical: "NEWS",
    };
  }

  //STAGE
  if (window.location.host === "alerts-msteams-connector-test.dataminr.com") {
    return {
      get_self_api:
        "https://gateway-stage.dataminr.com/userMgmt/3/users/get_self",
      get_lists_api: "https://gateway-stage.dataminr.com/account/2/get_lists",
      save_config_api:
        "https://gateway-stage.dataminr.com/integrations/1/msteams/config",
      get_delivery_info_api: "https://gateway-stage.dataminr.com/integrations/1/msteams/fetch",
      auth_url: "/auth/authenticate",
      news_vertical: "NEWS",
    };
  }

  //PROD
  if (window.location.host === "alerts-msteams-connector.dataminr.com") {
    return {
      get_self_api: "https://gateway.dataminr.com/userMgmt/3/users/get_self",
      get_lists_api: "https://gateway.dataminr.com/account/2/get_lists",
      save_config_api:
        "https://gateway.dataminr.com/integrations/1/msteams/config",
      get_delivery_info_api: "https://gateway.dataminr.com/integrations/1/msteams/fetch",
      auth_url: "/auth/authenticate",
      news_vertical: "NEWS",
    };
  }

  //DEFAULT TO LOCAL
  return {
    get_self_api:
      "https://gateway-stage.dataminr.com/userMgmt/3/users/get_self",
    get_lists_api: "https://gateway-stage.dataminr.com/account/2/get_lists",
    save_config_api:
      "https://gateway-stage.dataminr.com/integrations/1/msteams/config",
    get_delivery_info_api: "https://gateway-stage.dataminr.com/integrations/1/msteams/fetch",
    auth_url: "/auth/authenticate",
    news_vertical: "NEWS",
  };
};
